<template>
  <a-row class="homeroom">
    <a-col :span="24">
      <div class="coverDetail">
        <h2>Homeroom {{ selectedMapel }} | {{ header.kelas }}</h2>
        <div>
          {{ header.totalMurid }} Students | {{ header.tahunAjar }} ({{
            header.semester
          }})
        </div>
      </div>
      <div class="contentDetail">
        <div class="detail-slot">
          <div class="d-lg-flex mb-3">
            <a-form-item class="d-block d-lg-none">
              <a-select
                @change="handleSelectViewMobile"
                :value="selectView"
                size="large"
                style="width: 100%; height: 40px"
                :disabled="loadingTable"
              >
                <a-select-option value="Grades">{{
                  textRadio1
                }}</a-select-option>
                <a-select-option value="Presences">{{
                  textRadio2
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="d-none d-lg-block">
              <a-radio-group
                @change="handleSelectView"
                :value="selectView"
                button-style="solid"
                size="large"
                :disabled="loadingTable"
              >
                <a-radio-button value="Grades">{{
                  this.textRadio1
                }}</a-radio-button>
                <a-radio-button value="Presences">{{
                  this.textRadio2
                }}</a-radio-button>
                <!-- <a-radio-button value="Presences2">{{this.textRadio3}}</a-radio-button> -->
              </a-radio-group>
            </a-form-item>
            <a-form-item>
              <a-select
                v-if="selectView !== 'Presences2'"
                size="large"
                ref="SelectSubject"
                placeholder="Select Subject"
                :style="{
                  width: isDesktop ? '200px' : '100%',
                  height: '48px'
                }"
                :default-value="selectedMapel"
                @change="handleSelectMapel"
                class="ml-lg-2"
                :disabled="loadingTable"
              >
                <!-- <a-select-option value="All">All Subjects</a-select-option> -->
                <a-select-option
                  v-for="item in subjects"
                  :key="item.id_mata_pelajaran"
                  :value="item.id_mata_pelajaran"
                  >{{ item.mata_pelajaran.nama }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <!-- <a-select
              v-if="selectView === 'Presences' && selectedMapel !== 'All'"
              size="large"
              ref="Select Week"
              placeholder="Select Week"
              style="width: 140px; height: 48px;"
              default-value="All Week"
              @change="handleSelectMapel"
            >
              <a-select-option value="All">All Weeks</a-select-option>
              <a-select-option value="Week 1">Week 1</a-select-option>
              <a-select-option value="Week 2">Week 2</a-select-option>
            </a-select>-->
            <!-- <div class>
              <a-button @click="scrollTo" type="default" style="height:40px;">
                <a-icon type="sync" :spin="loadingRefresh" />Scroll to today
              </a-button>
            </div>-->
            <a-form-item class="ml-auto">
              <a-button
                :disabled="loadingTable"
                @click.prevent="refreshTable"
                type="default"
                style="height: 40px"
                block
              >
                <a-icon type="sync" :spin="loadingRefresh" />Refresh
              </a-button>
            </a-form-item>
          </div>
        </div>
        <a-table
          :columns="handleTableColumn"
          :data-source="
            selectView === 'Grades'
              ? selectedMapel === 'All'
                ? dataGrade
                : dataGradesByMapel
              : selectView === 'Presences'
              ? selectedMapel === 'All'
                ? dataPresences
                : dataPresencesByMapel
              : dataPresences2
          "
          :scroll="{ x: 1050, y: 500 }"
          :pagination="{ pageSize: 30, hideOnSinglePage: true }"
          :loading="loadingTable"
          size="small"
          class="table-homeroom"
          bordered
        >
          <a
            @click.prevent="toDetailHomeroom(record.key)"
            slot="Name"
            class="text-primary"
            slot-scope="text, record"
            >{{ text }}</a
          >
          <span slot="customTitle"> <a-icon type="smile-o" />Name </span>
          <span slot="Color" slot-scope="text">
            <div v-if="text >= 70 && text <= 100">
              <a-tag color="green"
                >{{ text }}{{ selectView === "Grades" ? "" : "%" }}</a-tag
              >
            </div>
            <div v-else-if="text >= 35 && text <= 69">
              <a-tag color="orange"
                >{{ text }}{{ selectView === "Grades" ? "" : "%" }}</a-tag
              >
            </div>
            <div v-else-if="text >= 0 && text <= 34">
              <a-tag color="red"
                >{{ text }}{{ selectView === "Grades" ? "" : "%" }}</a-tag
              >
            </div>
            <div v-else>
              <a-tag>{{ text }}{{ selectView === "Grades" ? "" : "%" }}</a-tag>
            </div>
          </span>
          <span class="mx-3 my-4" slot="ColorAttendance" slot-scope="text">
            <div v-if="text === 'default'">
              <span
                class="ml-3 kit__utils__donut kit__utils__donut--not-absent-yet"
              ></span>
              {{ "N/A" }}
            </div>
            <div v-else-if="text === 'present'">
              <span
                class="ml-3 kit__utils__donut kit__utils__donut--present"
              ></span>
              {{ "Present" }}
            </div>
            <div v-else-if="text === 'absent'">
              <span
                class="ml-3 kit__utils__donut kit__utils__donut--absent"
              ></span>
              {{ "Absent" }}
            </div>
            <div v-else-if="text === 'sick'">
              <span
                class="ml-3 kit__utils__donut kit__utils__donut--sick"
              ></span>
              {{ "Sick" }}
            </div>
            <div v-else-if="text === 'excused'">
              <span
                class="ml-3 kit__utils__donut kit__utils__donut--leave"
              ></span>
              {{ "Excused" }}
            </div>
          </span>
        </a-table>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import moment from 'moment'
const columnsGrade = [
  {
    title: 'NO',
    dataIndex: 'No',
    key: 'No',
    width: 50,
    align: 'center',
    fixed: 'left',
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'Name',
    width: 200,
    fixed: 'left',
    scopedSlots: { customRender: 'Name' },
  },
  {
    title: 'Average All',
    dataIndex: 'Average',
    key: 'Average',
    width: 120,
    fixed: 'left',
    align: 'center',
    scopedSlots: { customRender: 'Color' },
  },
  {
    title: 'Rank',
    dataIndex: 'Rank',
    key: 'Rank',
    width: 90,
    fixed: 'left',
    align: 'center',
    sorter: (a, b) => a.Rank - b.Rank,
  },
  {
    title: 'GRADE BY SUBJECTS',
    children: '',
  },
]
const columnsPresence = [
  {
    title: 'NO',
    dataIndex: 'No',
    key: 'No',
    width: 50,
    align: 'center',
    fixed: 'left',
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'Name',
    width: 200,
    fixed: 'left',
    scopedSlots: { customRender: 'Name' },
  },
  {
    title: 'Average Presences',
    dataIndex: 'Average',
    key: 'Average',
    width: 100,
    fixed: 'left',
    scopedSlots: { customRender: 'Color' },
  },
  {
    title: 'Sick',
    dataIndex: 'AllSick',
    key: 'Sick',
    width: 50,
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Excused',
    dataIndex: 'AllExcused',
    key: 'Excused',
    width: 85,
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Absent',
    dataIndex: 'AllAbsent',
    key: 'Absent',
    width: 75,
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'PRESENCES BY SUBJECTS',
    children: '',
  },
]
const columnsPresence2 = [
  {
    title: 'NO',
    dataIndex: 'No',
    key: 'No',
    width: 50,
    align: 'center',
    fixed: 'left',
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'Name',
    width: 200,
    fixed: 'left',
    scopedSlots: { customRender: 'Name' },
  },
  {
    title: 'PRESENCES BY DATES',
    children: '',
  },
]
const columnGradesByMapel = [
  {
    title: 'NO',
    dataIndex: 'No',
    key: 'No',
    width: 50,
    align: 'center',
    // fixed: 'left',
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'Name',
    width: 220,
    // fixed: 'left',
    scopedSlots: { customRender: 'Name' },
  },
  {
    title: 'Rank',
    dataIndex: 'Rank',
    key: 'Rank',
    width: 60,
    align: 'center',
    // fixed: 'left',
    // scopedSlots: { customRender: 'Color' },
  },
  {
    title: 'Avg Grade',
    dataIndex: 'AvgGrade',
    key: 'AvgGrade',
    width: 100,
    align: 'center',
    // fixed: 'left',
    scopedSlots: { customRender: 'Color' },
  },
  {
    title: '',
    children: '',
  },
]
const columnPresencesByMapel = [
  {
    title: 'NO',
    dataIndex: 'No',
    key: 'No',
    width: 50,
    align: 'center',
    fixed: 'left',
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'Name',
    width: 200,
    fixed: 'left',
    scopedSlots: { customRender: 'Name' },
  },
  {
    title: 'Avg. Presence',
    dataIndex: 'AveragePresence',
    key: 'AveragePresence',
    width: 90,
    fixed: 'left',
    scopedSlots: { customRender: 'Color' },
  },
  {
    title: 'Sick',
    dataIndex: 'Sick',
    key: 'Sick',
    width: 50,
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Excused',
    dataIndex: 'Excused',
    key: 'Excused',
    width: 85,
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Absent',
    dataIndex: 'Absent',
    key: 'Absent',
    width: 75,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '',
    children: '',
  },
]
export default {
  data() {
    return {
      loadingRefresh: false,
      loadingTable: false,
      columnsGrade,
      columnsPresence,
      columnsPresence2,
      columnGradesByMapel,
      columnPresencesByMapel,
      subjects: [],
      weeks: [],
      dataTable: [],
      dataGrade: [],
      dataPresences: [],
      dataPresences2: [],
      dataGradesByMapel: [],
      dataPresencesByMapel: [],
      selectedMapel: 'All',
      selectView: 'Grades',
      textRadio1: 'Grades',
      textRadio2: 'Presences',
      textRadio3: 'Presences (by dates)',
    }
  },
  methods: {
    toDetailHomeroom(id) {
      this.$router.push({ name: 'Detail Homeroom Teacher', params: { id } })
    },
    scrollTo() {
      // const table = document.querySelector('.table-homeroom')
      // console.log(table)
    },
    handleSelectMapel(value) {
      if (value !== 'All') {
        // const id = value.split('|')[0]
        // const title = value.split('|')[1]
        this.selectedMapel = value
        // console.log(this.selectedMapel)
        if (this.selectView === 'Grades') {
          this.columnGradesByMapel[4].title = 'GRADES STATUS'
          this.fetchDataTableGrade({
            idMataPelajaran: this.selectedMapel,
          })
          // console.log('select grade')
        } else if (this.selectView === 'Presences') {
          this.columnPresencesByMapel[6].title = 'PRESENCES STATUS'
          this.fetchDataTablePresence({
            idMataPelajaran: this.selectedMapel,
          })
        } else {
          // console.log('Masuk else select mapel')
        }
      } else {
        this.selectedMapel = value
      }
    },
    handleSelectViewMobile(value) {
      this.selectView = value
      this.handleSelectMapel(this.selectedMapel)
    },
    handleSelectView(e) {
      const delay = t => new Promise(resolve => setTimeout(resolve, t))
      if (e.target.value === 'Presences') {
        this.textRadio2 = 'Please Wait...'
        delay(100).then(() => {
          this.textRadio2 = 'Presences'
          // this.textRadio2 = 'Presences (by subjects)'
          this.selectView = e.target.value
          this.fetchDataTablePresence({
            idMataPelajaran: this.selectedMapel,
          })
          this.handleSelectMapel(this.selectedMapel)
        })
      } else if (e.target.value === 'Grades') {
        this.textRadio1 = 'Please Wait...'
        delay(100).then(() => {
          this.textRadio1 = 'Grades'
          this.selectView = e.target.value
          this.fetchDataTableGrade({
            idMataPelajaran: this.selectedMapel,
          })
          this.handleSelectMapel(this.selectedMapel)
        })
      } else if (e.target.value === 'Presences2') {
        this.textRadio3 = 'Please Wait...'
        delay(100).then(() => {
          this.textRadio3 = 'Presences (by dates)'
          this.selectView = e.target.value
        })
      }
    },
    refreshTable() {
      this.loadingRefresh = true
      if (this.selectView === 'Grades') {
        this.fetchDataTableGrade()
      } else {
        this.fetchDataTablePresence()
      }
    },
    async fetchDataHeader() {
      const isSuccess = await this.$store.dispatch('homeroom/FETCH_HOMEROOM_HEADER', { idGuru: this.user.id })

      return new Promise((resolve, reject) => {
        resolve(isSuccess)
      })
    },
    fetchDataSelect() {
      this.$store.dispatch('homeroom/FETCH_HOMEROOM_SELECT', { idGuru: this.user.id })
        .then(res => {
          res.selectMapel.unshift({
            id_mata_pelajaran: 'All',
            mata_pelajaran: {
              nama: 'All Subjects',
            },
          })
          this.subjects = res.selectMapel
          this.weeks = res.selectWeek
        })
    },
    fetchDataTableGrade(params = { idMataPelajaran: 'All' }) {
      this.loadingTable = true
      this.$store.dispatch('homeroom/FETCH_HOMEROOM_TABLE_GRADE', { idGuru: this.user.id, idMataPelajaran: params.idMataPelajaran })
        .then(res => {
          this.loadingRefresh = false
          this.loadingTable = false
          // console.log(res)
          if (params.idMataPelajaran === 'All') {
            const Columns = res.columnMapel.map(rowMapel => {
              const mapel = (
                <a-tooltip>
                  <template slot="title">
                    {rowMapel.nama}
                  </template>
                  {rowMapel.code}
                </a-tooltip>
              )
              return {
                title: (<span>{mapel}</span>),
                dataIndex: `Mapel${rowMapel.id}`,
                key: `Mapel${rowMapel.id}`,
                width: 80,
                scopedSlots: { customRender: 'Color' },
                align: 'center',
              }
            })
            let no = 1
            const dataTable = res.rowMurid.map(rowMurid => {
              const nilaiBundle = [...rowMurid.gradeBySubject]
              const newNilaiBundle = {}
              nilaiBundle.forEach(rowNilai => {
                newNilaiBundle[`Mapel${rowNilai.idMapel}`] = rowNilai.grade !== null ? parseFloat(rowNilai.grade).toFixed(1) : '-'
              })
              return {
                key: rowMurid.idMurid,
                No: no++,
                Name: rowMurid.namaMurid,
                ...newNilaiBundle,
                // Total: rowMurid.totalNilai,
                Average: rowMurid.averageAll !== null ? parseFloat(rowMurid.averageAll).toFixed(1) : '-',
                Rank: rowMurid.rank,
              }
            })
            this.columnsGrade[4].children = Columns
            this.dataGrade = dataTable
          } else {
            const columns = [
              {
                title: 'PH1',
                dataIndex: 'PH1',
                key: 'PH1',
                width: 80,
                scopedSlots: { customRender: 'Color' },
                align: 'center',
              },
              {
                title: 'PH2',
                dataIndex: 'PH2',
                key: 'PH2',
                width: 80,
                scopedSlots: { customRender: 'Color' },
                align: 'center',
              },
              {
                title: 'PTS',
                dataIndex: 'PTS',
                key: 'PTS',
                width: 80,
                scopedSlots: { customRender: 'Color' },
                align: 'center',
              },
            ]
            if (res.semester === 'Ganjil') {
              columns[3] = {
                title: 'PAS',
                dataIndex: 'PAS',
                key: 'PAS',
                width: 80,
                scopedSlots: { customRender: 'Color' },
                align: 'center',
              }
            } else if (res.semester === 'Genap') {
              columns[3] = {
                title: 'PAT',
                dataIndex: 'PAT',
                key: 'PAT',
                width: 80,
                scopedSlots: { customRender: 'Color' },
                align: 'center',
              }
            }
            let no = 1
            const dataTable = res.rowMurid.map(el => {
              return {
                key: el.id_murid,
                No: no++,
                Name: el.nama_murid,
                Rank: el.rank,
                AvgGrade: el.average,
                PH1: el.PH1,
                PH2: el.PH2,
                PTS: el.PTS,
                PAS: el.PAS ?? 0,
                PAT: el.PAT,
              }
            })
            this.columnGradesByMapel[4].children = columns
            this.dataGradesByMapel = dataTable
          }
        })
    },
    fetchDataTablePresence(params = { idMataPelajaran: 'All' }) {
      this.loadingTable = true
      this.$store.dispatch('homeroom/FETCH_HOMEROOM_TABLE_PRESENCES', { idGuru: this.user.id, idMataPelajaran: params.idMataPelajaran })
        .then(res => {
          this.loadingRefresh = false
          this.loadingTable = false
          // if (this.selectView === 'Grades' || this.selectView === 'Presences') {
          //   console.log('Masuk 1')
          if (params.idMataPelajaran === 'All') {
            const Columns = res.columnMapel.map(rowMapel => {
              const mapel = (
                <a-tooltip>
                  <template slot="title">
                    {rowMapel.nama}
                  </template>
                  {rowMapel.code}
                </a-tooltip>
              )
              return {
                title: (<span>{mapel}</span>),
                dataIndex: `Mapel${rowMapel.id_mata_pelajaran}`,
                key: `Mapel${rowMapel.id_mata_pelajaran}`,
                width: 80,
                scopedSlots: { customRender: 'Color' },
                align: 'center',
              }
            })
            let no = 1
            const dataTable = res.rowMurid.map(rowMurid => {
              const presencesBundle = [...rowMurid.presencesBundle]
              const newpresencesBundle = {}
              presencesBundle.forEach(rowNilai => {
                let persenPresence = rowNilai.class_presences
                if (persenPresence === 100) {
                  persenPresence = parseFloat(persenPresence).toFixed(0)
                } else {
                  persenPresence = parseFloat(persenPresence).toFixed(1)
                }
                newpresencesBundle[`Mapel${rowNilai.id_mata_pelajaran}`] = persenPresence
              })
              return {
                key: rowMurid.id_murid,
                No: no++,
                Name: rowMurid.nama_murid,
                ...newpresencesBundle,
                Total: rowMurid.totalNilai,
                Average: parseFloat(rowMurid.avgAll).toFixed(2),
                AllSick: rowMurid.countSick,
                AllExcused: rowMurid.countExcused,
                AllAbsent: rowMurid.countAbsent,
              }
            })
            this.columnsPresence[6].children = Columns
            this.dataPresences = dataTable
          } else {
            // console.log('Masuk by mapel presences', res.columnTanggal)
            const Columns = res.columnTanggal.map(rowTanggal => {
              const hariIni = moment().format('DD/MM')
              let tanggalSingkat = moment(rowTanggal.tanggal_sesi).format('DD/MM')
              const isAfter = moment(tanggalSingkat, 'DD/MM').isAfter(moment(hariIni, 'DD/MM'))
              const isSame = moment(tanggalSingkat, 'DD/MM').isSame(moment(hariIni, 'DD/MM'))
              const isBefore = moment(tanggalSingkat, 'DD/MM').isBefore(moment(hariIni, 'DD/MM'))
              let color = 'danger'
              if (isBefore) {
                color = 'danger'
              } else if (isSame) {
                tanggalSingkat = 'Today'
                color = 'primary'
              } else if (isAfter) {
                color = 'default'
              }
              const tanggal = (
                <a-tooltip>
                  <template slot="title">
                    {moment(rowTanggal.tanggal_sesi).format('DD MMMM YYYY')}
                  </template>
                  <a-button type={color} class="ml-3">{tanggalSingkat}</a-button>
                </a-tooltip>
              )
              return {
                title: (<span>{tanggal}</span>),
                dataIndex: `Tanggal${rowTanggal.id}`,
                key: `Tanggal${rowTanggal.id}`,
                width: 120,
                scopedSlots: { customRender: 'ColorAttendance' },
                align: 'left',
              }
            })
            let no = 1
            const dataTable = res.rowMurid.map(rowMurid => {
              const attendanceBundle = [...rowMurid.attendanceBundle]
              const newattendanceBundle = {}
              let sick = 0
              let excused = 0
              let absent = 0
              attendanceBundle.forEach(rowAttendance => {
                if (rowAttendance.status === 'sick') {
                  sick++
                } else if (rowAttendance.status === 'excused') {
                  excused++
                } else if (rowAttendance.status === 'absent') {
                  absent++
                }
                newattendanceBundle[`Tanggal${rowAttendance.idSesi}`] = rowAttendance.status
              })
              // console.log(parseFloat(rowMurid.average).toFixed(1))
              return {
                key: rowMurid.idMurid,
                No: no++,
                Name: rowMurid.namaMurid,
                Sick: sick,
                Excused: excused,
                Absent: absent,
                ...newattendanceBundle,
                AveragePresence: rowMurid.average === 100 ? 100 : parseFloat(rowMurid.average).toFixed(1),
                align: 'left',
              }
            })
            // console.log(dataTable)
            this.columnPresencesByMapel[6].children = Columns
            this.dataPresencesByMapel = dataTable
          }
          // } else if (this.selectView === 'Presences2') {
          //   console.log('Masuk 2')
          // }
        })
    },
    fetchDataTablePresence2() {
      // console.log('Fetch presence by dates')
      const dataTable = [
        {
          key: 1,
          No: 1,
          Name: 'Abdul Zakir',
        },
      ]
      this.dataPresences2 = dataTable
    },
  },
  created() {
    this.fetchDataHeader()
      .then(isSuccess => {
        if (isSuccess) {
          this.fetchDataSelect()
          this.fetchDataTableGrade()
          this.fetchDataTablePresence()
          // this.fetchDataTablePresence2()
        }
      })
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    header() {
      return this.$store.state.homeroom.header
    },
    handleTableColumn() {
      let columns
      // console.log(this.selectedMapel)
      if (this.selectedMapel === 'All') {
        if (this.selectView === 'Grades') {
          columns = this.columnsGrade
        } else if (this.selectView === 'Presences') {
          columns = this.columnsPresence
        } else if (this.selectView === 'Presences2') {
          columns = this.columnsPresence2
        }
      } else {
        if (this.selectView === 'Grades') {
          columns = this.columnGradesByMapel
        } else if (this.selectView === 'Presences') {
          columns = this.columnPresencesByMapel
        } else if (this.selectView === 'Presences2') {
          columns = this.columnsPresence2
        }
      }
      return columns
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style lang="scss">
.homeroom {
  .coverDetail {
    width: 100%;
    min-height: 145px;
    background-color: #008080;
    border-radius: 10px;
    padding: 20px 40px;
    color: #ffffff;
  }

  h2 {
    color: #ffffff;
  }

  .contentDetail {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #0419301a;
    margin-left: 1px;
  }

  .contentDetail .detail-slot {
    padding: 10px 0;
  }
  .table-homeroom {
    .ant-table-fixed-left {
      @media (max-width: 426px) {
        width: 50%;
        overflow-x: auto !important;
      }
    }
    .ant-table-scroll {
      .ant-table-body .ant-table-fixed colgroup col:first-child {
        @media (max-width: 426px) {
          width: 12rem !important;
          min-width: 12rem !important;
          overflow-x: hidden;
        }
      }
      .ant-table-header .ant-table-fixed colgroup col:first-child {
        @media (max-width: 426px) {
          width: 12rem !important;
          min-width: 12rem !important;
        }
      }
    }
  }
}
</style>
