var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',{staticClass:"homeroom"},[_c('a-col',{attrs:{"span":24}},[_c('div',{staticClass:"coverDetail"},[_c('h2',[_vm._v("Homeroom "+_vm._s(_vm.selectedMapel)+" | "+_vm._s(_vm.header.kelas))]),_c('div',[_vm._v(" "+_vm._s(_vm.header.totalMurid)+" Students | "+_vm._s(_vm.header.tahunAjar)+" ("+_vm._s(_vm.header.semester)+") ")])]),_c('div',{staticClass:"contentDetail"},[_c('div',{staticClass:"detail-slot"},[_c('div',{staticClass:"d-lg-flex mb-3"},[_c('a-form-item',{staticClass:"d-block d-lg-none"},[_c('a-select',{staticStyle:{"width":"100%","height":"40px"},attrs:{"value":_vm.selectView,"size":"large","disabled":_vm.loadingTable},on:{"change":_vm.handleSelectViewMobile}},[_c('a-select-option',{attrs:{"value":"Grades"}},[_vm._v(_vm._s(_vm.textRadio1))]),_c('a-select-option',{attrs:{"value":"Presences"}},[_vm._v(_vm._s(_vm.textRadio2))])],1)],1),_c('a-form-item',{staticClass:"d-none d-lg-block"},[_c('a-radio-group',{attrs:{"value":_vm.selectView,"button-style":"solid","size":"large","disabled":_vm.loadingTable},on:{"change":_vm.handleSelectView}},[_c('a-radio-button',{attrs:{"value":"Grades"}},[_vm._v(_vm._s(this.textRadio1))]),_c('a-radio-button',{attrs:{"value":"Presences"}},[_vm._v(_vm._s(this.textRadio2))])],1)],1),_c('a-form-item',[(_vm.selectView !== 'Presences2')?_c('a-select',{ref:"SelectSubject",staticClass:"ml-lg-2",style:({
                width: _vm.isDesktop ? '200px' : '100%',
                height: '48px'
              }),attrs:{"size":"large","placeholder":"Select Subject","default-value":_vm.selectedMapel,"disabled":_vm.loadingTable},on:{"change":_vm.handleSelectMapel}},_vm._l((_vm.subjects),function(item){return _c('a-select-option',{key:item.id_mata_pelajaran,attrs:{"value":item.id_mata_pelajaran}},[_vm._v(_vm._s(item.mata_pelajaran.nama))])}),1):_vm._e()],1),_c('a-form-item',{staticClass:"ml-auto"},[_c('a-button',{staticStyle:{"height":"40px"},attrs:{"disabled":_vm.loadingTable,"type":"default","block":""},on:{"click":function($event){$event.preventDefault();return _vm.refreshTable($event)}}},[_c('a-icon',{attrs:{"type":"sync","spin":_vm.loadingRefresh}}),_vm._v("Refresh ")],1)],1)],1)]),_c('a-table',{staticClass:"table-homeroom",attrs:{"columns":_vm.handleTableColumn,"data-source":_vm.selectView === 'Grades'
            ? _vm.selectedMapel === 'All'
              ? _vm.dataGrade
              : _vm.dataGradesByMapel
            : _vm.selectView === 'Presences'
            ? _vm.selectedMapel === 'All'
              ? _vm.dataPresences
              : _vm.dataPresencesByMapel
            : _vm.dataPresences2,"scroll":{ x: 1050, y: 500 },"pagination":{ pageSize: 30, hideOnSinglePage: true },"loading":_vm.loadingTable,"size":"small","bordered":""},scopedSlots:_vm._u([{key:"Name",fn:function(text, record){return _c('a',{staticClass:"text-primary",on:{"click":function($event){$event.preventDefault();return _vm.toDetailHomeroom(record.key)}}},[_vm._v(_vm._s(text))])}},{key:"Color",fn:function(text){return _c('span',{},[(text >= 70 && text <= 100)?_c('div',[_c('a-tag',{attrs:{"color":"green"}},[_vm._v(_vm._s(text)+_vm._s(_vm.selectView === "Grades" ? "" : "%"))])],1):(text >= 35 && text <= 69)?_c('div',[_c('a-tag',{attrs:{"color":"orange"}},[_vm._v(_vm._s(text)+_vm._s(_vm.selectView === "Grades" ? "" : "%"))])],1):(text >= 0 && text <= 34)?_c('div',[_c('a-tag',{attrs:{"color":"red"}},[_vm._v(_vm._s(text)+_vm._s(_vm.selectView === "Grades" ? "" : "%"))])],1):_c('div',[_c('a-tag',[_vm._v(_vm._s(text)+_vm._s(_vm.selectView === "Grades" ? "" : "%"))])],1)])}},{key:"ColorAttendance",fn:function(text){return _c('span',{staticClass:"mx-3 my-4"},[(text === 'default')?_c('div',[_c('span',{staticClass:"ml-3 kit__utils__donut kit__utils__donut--not-absent-yet"}),_vm._v(" "+_vm._s("N/A")+" ")]):(text === 'present')?_c('div',[_c('span',{staticClass:"ml-3 kit__utils__donut kit__utils__donut--present"}),_vm._v(" "+_vm._s("Present")+" ")]):(text === 'absent')?_c('div',[_c('span',{staticClass:"ml-3 kit__utils__donut kit__utils__donut--absent"}),_vm._v(" "+_vm._s("Absent")+" ")]):(text === 'sick')?_c('div',[_c('span',{staticClass:"ml-3 kit__utils__donut kit__utils__donut--sick"}),_vm._v(" "+_vm._s("Sick")+" ")]):(text === 'excused')?_c('div',[_c('span',{staticClass:"ml-3 kit__utils__donut kit__utils__donut--leave"}),_vm._v(" "+_vm._s("Excused")+" ")]):_vm._e()])}}])},[_c('span',{attrs:{"slot":"customTitle"},slot:"customTitle"},[_c('a-icon',{attrs:{"type":"smile-o"}}),_vm._v("Name ")],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }